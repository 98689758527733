import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled(Link)`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.color.secondary.minus};
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.color.separator};
  margin-bottom: 20px;
`;

const Image = styled(Img)`
  display: flex;
  opacity: 0.9;
  width: 100%;
  height: auto;
  border-radius: 2px;
  margin-bottom: 10px;
  transition: ${(props) => props.theme.transition.create()};

  &:hover {
    opacity: 1;
  }
`;

const Bio = styled.div`
  color: ${(props) => props.theme.color.secondary.main};
  display: flex;
  font-size: 16px;
`;

const MediaAboutMe: React.FC = () => {
  let link = `/about`;

  return (
    <StaticQuery
      query={mediaAboutMeQuery}
      render={(data) => {
        return (
          <Container>
            <Title to={link}>{"ABOUT ME"}</Title>
            <Link to={link}>
              <Image fluid={data.profilePic.childImageSharp.fluid} />
            </Link>
            <Bio>{`I'm Tom, a father, husband, and professional tinkerer.`}</Bio>
          </Container>
        );
      }}
    />
  );
};

const mediaAboutMeQuery = graphql`
  query MediaAboutMeQuery {
    profilePic: file(absolutePath: { regex: "/profile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default MediaAboutMe;
