import React, { useState } from "react";
import styled from "styled-components";
import { useSubscribeMutation } from "../backends/graph/sdk";

const emailPattern = /.+@.+\..+/;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.color.secondary.minus};
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.color.separator};
  margin-bottom: 20px;
`;

const Cta = styled.div`
  display: flex;
  color: ${(props) => props.theme.color.secondary.main};
  font-size: 16px;
  margin-bottom: 20px;
`;

const Email = styled.input`
  transition: ${(props) => props.theme.transition.create()};
  font-size: 18px;
  color: ${(props) => props.theme.color.secondary.minus};
  background-color: ${(props) => props.theme.color.primary.plus};
  box-shadow: none;
  border: 1px solid ${(props) => props.theme.color.primary.minus};
  border-radius: 2px;
  padding: 6px 12px;
  outline: none;
  margin-bottom: 20px;
  height: 45px;
  width: 100%;

  &:focus {
    border-color: ${(props) => props.theme.color.secondary.minus};
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${(props) => props.theme.transition.create()};
  background-color: ${(props) => props.theme.color.secondary.main};
  color: ${(props) => props.theme.color.primary.main};
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
  height: 45px;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.color.secondary.plus};
  }
`;

const MediaSubscribe: React.FC = () => {
  const [email, setEmail] = useState("");

  const [subscribe] = useSubscribeMutation();

  return (
    <Container>
      <Title>{"SUBSCRIBE"}</Title>
      <Cta>
        {
          "Subscribe to receive the newsletter and email notifications for future posts."
        }
      </Cta>
      <Email
        placeholder={"Your email address"}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <Button
        onClick={async () => {
          if (!email || !emailPattern.test(email)) {
            return;
          }

          await subscribe({
            variables: { email, source: "blog:tleef:subscribe" },
          });

          setEmail("");
        }}
      >
        {"SUBSCRIBE"}
      </Button>
    </Container>
  );
};

export default MediaSubscribe;
