import React from "react";
import styled from "styled-components";

import SocialLinks from "./SocialLinks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.color.secondary.minus};
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.color.separator};
  margin-bottom: 20px;
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default () => {
  return (
    <Container>
      <Title>{"CONNECT"}</Title>
      <Links>
        <SocialLinks />
      </Links>
    </Container>
  );
};
