import React from "react";
import styled from "styled-components";
import Window from "@tleef/react-window";

import AboutMe from "./MediaAboutMe";
import Social from "./MediaSocial";
import Subscribe from "./MediaSubscribe";

const StyledMediaBar = styled.div`
  position: relative;
  margin-top: 40px;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
`;

interface Props {
  aboutMe: boolean;
  social: boolean;
  subscribe: boolean;
}

interface State {
  fixedTop: boolean;
  fixedBottom: boolean;
  style?: React.CSSProperties;
}

export default class MediaBar extends React.Component<Props, State> {
  private readonly container = React.createRef<HTMLDivElement>();
  private readonly content = React.createRef<HTMLDivElement>();
  private originalTop: number = 0;
  private lastScrollY?: number;

  static defaultProps = {
    aboutMe: true,
    subscribe: true,
    social: true,
  };

  state: State = {
    fixedTop: false,
    fixedBottom: false,
    style: undefined,
  };

  render() {
    const { style } = this.state;

    return (
      <Window
        onScroll={() => this.onWindowScroll()}
        onResize={() => this.onWindowResize()}
      >
        <StyledMediaBar>
          <Container style={style} ref={this.container}>
            <Content
              ref={(el) => {
                this.content.current = el;

                if (el && !this.originalTop) {
                  this.originalTop =
                    el.getBoundingClientRect().top + window.scrollY;
                }
              }}
            >
              {this.props.aboutMe && <AboutMe />}
              {this.props.social && <Social />}
              {this.props.subscribe && <Subscribe />}
            </Content>
          </Container>
        </StyledMediaBar>
      </Window>
    );
  }

  onWindowResize() {
    const { style } = this.state;

    if (style && window.innerWidth < 960) {
      this.setState({
        fixedTop: false,
        fixedBottom: false,
        style: undefined,
      });
    }
  }

  onWindowScroll() {
    let marginBottom = 140;

    let container = this.container.current;
    let content = this.content.current;

    if (!container || !content) {
      return;
    }

    const scrollY = window.scrollY;
    const lastScrollY = this.lastScrollY;
    this.lastScrollY = scrollY;

    if (lastScrollY == null) {
      return;
    }

    const { fixedTop, fixedBottom, style } = this.state;

    const body = document.body;
    const html = document.documentElement;

    const bounds = content.getBoundingClientRect();
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const barHeight = bounds.height + this.originalTop + marginBottom + 40; // bar has margin-bottom: 40px when not fixed

    if (window.innerWidth < 960 || docHeight - barHeight < 2) {
      if (style) {
        this.setState({
          fixedTop: false,
          fixedBottom: false,
          style: undefined,
        });
      }

      return;
    }

    // media bar is too short for window
    if (this.originalTop + bounds.height < window.innerHeight) {
      if (!fixedTop) {
        this.setState({
          fixedTop: true,
          fixedBottom: false,
          style: {
            position: "fixed",
            top: this.originalTop,
            width: bounds.width,
          },
        });
      }
      return;
    }

    // fixed to top and scrolling down
    if (fixedTop && scrollY > lastScrollY) {
      // unfix
      return this.setState({
        fixedTop: false,
        fixedBottom: false,
        style: {
          position: "absolute",
          top: window.scrollY,
          width: bounds.width,
        },
      });
    } else if (fixedBottom && scrollY < lastScrollY) {
      // fixed to bottom and scrolling up
      // unfix from bottom
      return this.setState({
        fixedTop: false,
        fixedBottom: false,
        style: {
          position: "absolute",
          top:
            window.scrollY +
            window.innerHeight -
            bounds.height -
            this.originalTop -
            marginBottom,
          width: bounds.width,
        },
      });
    } else if (!fixedBottom && !fixedTop) {
      // not fixed
      // aligned with original position
      if (bounds.top > this.originalTop) {
        // fix to top
        return this.setState({
          fixedTop: true,
          fixedBottom: false,
          style: {
            position: "fixed",
            top: this.originalTop,
            width: bounds.width,
          },
        });
      } else if (bounds.bottom < window.innerHeight - marginBottom) {
        // aligned with bottom
        // fix to bottom
        return this.setState({
          fixedTop: false,
          fixedBottom: true,
          style: {
            position: "fixed",
            top: window.innerHeight - bounds.height - marginBottom,
            width: bounds.width,
          },
        });
      }
    }
  }
}
